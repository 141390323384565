<template>
  <div class="main">
    <div class="search">
      <div style="width: 84%;display: flex;justify-content: space-between">
        <!--        <el-input v-model="dataTable.evaluation_name" placeholder="搜索测评名称" size="small"></el-input>-->
        <el-select v-model="evaluation_name" :loading="loading" :remote-method="remoteMethod" filterable placeholder="搜索测评名称" remote reserve-keyword size="small" @change="changeEvaluation" @enter="onSearch">
          <el-option v-for="item in evaluationArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-select v-model="dataTable.result" class="el_input" collapse-tags multiple placeholder="筛选全量表异常指标项" size="small" @enter="onSearch">
          <el-option v-for="item in options[0]" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-select v-model="dataTable.positive" class="el_input" placeholder="筛选效度量表总分分段" size="small" @enter="onSearch">
          <el-option v-for="item in options[1]" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-select v-model="dataTable.school_id" class="el_input" placeholder="筛选校区" size="small" @change="onSchool" @enter="onSearch">
          <el-option v-for="item in options[2]" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-select v-model="dataTable.grade_id" class="el_input" placeholder="筛选年级" size="small" @change="onGrade" @enter="onSearch">
          <el-option v-for="item in options[3]" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-select v-model="dataTable.class_id" class="el_input" placeholder="筛选行政班" size="small" @change="()=>this.$forceUpdate()" @enter="onSearch">
          <el-option v-for="item in options[4]" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div style="margin-left: 2.08%">
        <el-button size="small" type="primary" @click="onSearch">搜索</el-button>
        <el-button size="small" @click="onReset">重置</el-button>
      </div>
    </div>

    <div class="crisis-early-warning">
      <div style="display: flex;justify-content: space-between">
        <div style="width: 125px;height: 30px;background: rgba(255,32,32,0.15);border-radius: 24px 24px 24px 24px;text-align: center;line-height: 30px;font-size: 20px;font-weight: 500;color: rgba(255,32,32,0.9);">危机预警</div>
        <div v-if="crisisEarlyWarning.length" style="font-size: 16px;font-weight: 400;color: #5A6DE5;cursor: pointer" @click="viewMore">查看更多 ></div>
      </div>
      <el-table v-if="crisisEarlyWarning.length" :data="crisisEarlyWarning" stripe style="width: 100%;margin-top: 18px">
        <el-table-column label="学生姓名" prop="student"></el-table-column>
        <el-table-column label="学号" prop="student_no"></el-table-column>
        <el-table-column label="全量表总分" prop="average"></el-table-column>
        <el-table-column label="全量表异常指标项" prop="result"></el-table-column>
        <el-table-column label="效度量表得分" prop="positive"></el-table-column>
        <el-table-column label="行政班" prop="class"></el-table-column>
        <el-table-column label="年级" prop="grade"></el-table-column>
        <el-table-column label="校区" prop="school"></el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button size="small" type="text" @click="onExamine(scope.row)">查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <t-result v-else text="目前暂时还没有数据哦" type="empty"></t-result>
    </div>

    <div class="crisis-early-warning">
      <div style="display: flex;justify-content: space-between">
        <div style="width: 168px;height: 30px;background: rgba(255,32,32,0.15);border-radius: 24px 24px 24px 24px;text-align: center;line-height: 30px;font-size: 20px;font-weight: 500;color: rgba(255,32,32,0.9);">班级平均分预警</div>
      </div>
      <div v-if="classAverageScoreAlert.length" style="transition: height 0.3s ease">
        <el-table :data="displayState===0?classAverageScoreAlert.slice(0,5):classAverageScoreAlert" stripe style="width: 100%;margin-top: 18px">
          <el-table-column label="测评名称" prop="name"></el-table-column>
          <el-table-column label="行政班" prop="class"></el-table-column>
          <el-table-column label="全量表平均分" prop="score"></el-table-column>
          <el-table-column label="校区" prop="school"></el-table-column>
          <el-table-column label="年级" prop="grade"></el-table-column>
          <el-table-column label="操作" width="80">
            <template slot-scope="scope">
              <el-button size="small" type="text" @click="onCrisisEarlyWarning(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="classAverageScoreAlert.length>5" style="font-size: 16px;font-weight: 500;color: #5A6DE5;margin-top: 24px;text-align: center;cursor: pointer" @click="()=>displayState=displayState===0?1:0">
          <i :class="displayState===0?'el-icon-arrow-down':'el-icon-arrow-up'"></i>
          {{ displayState === 0 ? '展开' : '收起' }}
        </div>
      </div>
      <t-result v-else text="目前暂时还没有数据哦" type="empty"></t-result>
    </div>

    <EchartsList key="0" :pie="efficiency.pie" :tableData="efficiency.list" index="0" title="效度量表" @onExamine="onExamine"/>
    <EchartsList v-for="(item,index) in abnormal" :id="item.id" :key="(index+2)" :index="(index+2)+''" :pie="item.pie" :tableData="item.list" :title="item.name" @onExamine="onExamine"/>
  </div>
</template>

<script>
import Empty from "@/views/moral/teacher-work/components/Empty.vue";
import TResult from "@/components/Result.vue";
import EchartsList from "@/views/data/appraisal/mht-details/component/EchartsList.vue";

export default {
	_config:{"route":{"path":"details","meta":{"title":"数据看板"}}},
  components: {EchartsList, TResult, Empty},
  data() {
    return {
      options: Array(5).fill([]),
      dataTable: {},
      crisisEarlyWarning: [],
      classAverageScoreAlert: [],
      displayState: 0,
      abnormal: [],
      efficiency: [],
      parameters: {},
      loading: false,
      evaluationArr: [],
      evaluation_name: '',
      backName: '',
    }
  },
  methods: {
    onExamine(row) {
      let id = row.scl_id ? row.scl_id : row.id
      this.$router.push('/moral/task-manage/test-mht/examine?id=' + id)
    },
    viewMore() {
      this.$router.push({path: '/moral/task-manage/test-mht/list'})
      sessionStorage.setItem('dataTable', JSON.stringify({...this.parameters, average: 2}))
    },

    onCrisisEarlyWarning(val) {
      this.$router.push({path: '/moral/task-manage/test-mht/list'})
      sessionStorage.setItem('dataTable', JSON.stringify({class_id: val.class_id, grade_id: val.grade_id, school_id: val.school_id, name: val.evaluation, evaluation_id: val.evaluation_id}))
    },

    onSchool(val) {
      this.dataTable.grade_id = ''
      this.dataTable.class_id = ''
      this.options[3] = []
      this.options[4] = []
      this.$_axios2.get('api/evaluation/result/get-range?school_id=' + val).then(res => {
        this.options[3] = res.data.data.grade
        this.options[4] = res.data.data.class
        this.$forceUpdate()
      })
    },

    getData() {
      let params = {...this.dataTable}
      this.parameters = {...this.dataTable}
      this.parameters.name = this.backName
      params.result = params.result ? params.result.join(',') : ''
      this.$_axios2.get('api/evaluation/statistics/mht-crisis', {params: {...params}}).then(res => {
        this.crisisEarlyWarning = res.data.data
      }).finally(() => this.loading = false)
      this.$_axios2.get('api/evaluation/statistics/class-average', {params: {...params}}).then(res => {
        this.classAverageScoreAlert = res.data.data
      }).finally(() => this.loading = false)
      this.$_axios2.get('api/evaluation/statistics/type-crisis', {params: {...params}}).then(res => {
        this.abnormal = res.data.data
      }).finally(() => this.loading = false)
      this.$_axios2.get('api/evaluation/statistics/validity', {params: {...params}}).then(res => {
        this.efficiency = res.data.data
      }).finally(() => this.loading = false)
    },

    onGrade(val) {
      this.dataTable.class_id = ''
      this.options[4] = []
      this.$_axios2.get('api/evaluation/result/get-range?grade_id=' + val + '&school_id=' + this.dataTable.school_id).then(res => {
        this.options[4] = res.data.data.class
        this.$forceUpdate()
      })
    },

    onReset() {
      this.dataTable = {}
      this.parameters = {}
      this.getData()
      this.options[3] = []
      this.options[4] = []
      this.evaluation_name = ''
      this.backName = ''
    },

    onSearch() {
      this.getData()
    },

    remoteMethod(query) {
      this.loading = true;
      this.$_axios2.get('/api/evaluation/result/keyword-search', {params: {keyword: query}}).then(res => {
        this.evaluationArr = res.data.data
      }).finally(() => this.loading = false)

    },
    changeEvaluation(val) {
      this.dataTable.evaluation_id = val
      this.backName = this.evaluationArr.find(item => item.id === val).name
    }
  },

  mounted() {
    this.getData()
  },

  created() {
    this.$_axios2.get('api/evaluation/statistics/mht-search').then(res => {
      this.options[0] = res.data.data.result
      this.options[1] = res.data.data.positive
      this.$forceUpdate()
    })
    this.$_axios2.get('api/evaluation/result/get-range').then(res => {
      this.options[2] = res.data.data.school
      this.$forceUpdate()
    })
  },
}
</script>
<style lang="scss" scoped>
.main {
  width: 100%;
  background: #f7f7f7 !important;
  padding-right: 1.58%;
  box-sizing: border-box;
}

.search {
  width: 100%;
  height: 80px;
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding-left: 1.55%;
}

.crisis-early-warning {
  width: 100%;
  min-height: 94px;
  background: #FFFFFF;
  border-radius: 8px 8px 8px 8px;
  margin-top: 20px;
  box-sizing: border-box;
  padding: 16px 1.86% 30px;
}

::v-deep.el-input {
  width: 15.5% !important;
}

::v-deep.el-select {
  width: 15.5% !important;
}

::v-deep th {
  background: #EEF1FA !important;
}
</style>
