<script>
import * as echarts from 'echarts';
import TResult from "@/components/Result.vue";

export default {
  name: "EchartsList",
  components: {TResult},
  props: {
    title: String,
    tableData: {
      type: Array,
      default: () => []
    },
    index: String,
    pie: {
      type: [Object, Array],
      default: () => ({
        green: 0,
        gray: 0,
        red: 0
      })
    },
    id: [Number, String]
  },

  watch: {
    pie: {
      handler(val) {
        if (!Array.isArray(val)) {
          this.rendering()
        }
      },
      immediate: true
    }
  },


  methods: {
    onExamine(row) {
      this.$emit('onExamine', row)
    },
    rendering() {
      if (!Array.isArray(this.pie)) {
        this.$nextTick(() => {
          let chartDom = document.getElementById('main-echarts' + this.index);

          let myChart = echarts.init(chartDom);
          let option = this.index !== '0' ? {
            legend: {
              right: 0,
              bottom: 0,
              orient: 'vertical',
              itemWidth: 20,
              itemHeight: 20,
              data: ['得分<3', '3≤得分≤8', (this.id == 1 || this.id == 6) ? '8＜得分≤15' : '8＜得分≤10'],
            },
            tooltip: {
              trigger: 'item',
              formatter: (params) => {
                return `<div>
                    <div style="display: flex;align-items: center;height: 24rem">
                      <div style="width: 6rem;height: 6rem;background: ${params.color.colorStops[0].color};border-radius: 50%;margin-right: 8rem"></div>
                      ${params.name}
                    </div>
                    <div style="font-size: 18rem;font-weight: 500;color: #3D3D3D;margin-left: 12rem">${params.percent}%</div>
                  </div>`
              },
            },
            series: [
              {
                type: 'pie',
                radius: ['25%', '75%'],
                data: [
                  {
                    name: '得分<3', value: this.pie.green,
                    itemStyle: {
                      color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        y2: 1,
                        x2: 1,
                        colorStops: [
                          {offset: 0, color: '#2FD931'},
                          {offset: 1, color: '#4BD44D'}
                        ],
                      },
                      borderWidth: this.pie.green != 0 ? 4 : 0,
                      borderColor: '#FFF',
                      shadowColor: '#2FD931',
                      borderRadius: 10,
                      shadowBlur: 1,
                    }
                  },
                  {
                    name: '3≤得分≤8', value: this.pie.gray, itemStyle: {
                      color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        y2: 1,
                        x2: 1,
                        colorStops: [
                          {offset: 0, color: '#B1C3FF'},
                          {offset: 1, color: '#C8D5FF'}
                        ],
                      },
                      borderWidth: this.pie.gray != 0 ? 4 : 0,
                      borderColor: '#FFF',
                      shadowColor: '#B1C3FF',
                      borderRadius: 10,
                      shadowBlur: 1,
                    }
                  },
                  {
                    name: (this.id == 1 || this.id == 6) ? '8＜得分≤15' : '8＜得分≤10', value: this.pie.red, itemStyle: {
                      color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        y2: 1,
                        x2: 1,
                        colorStops: [
                          {offset: 0, color: '#FF4D4D'},
                          {offset: 1, color: '#FF7D7D'}
                        ],
                      },
                      borderWidth: this.pie.red != 0 ? 4 : 0,
                      borderColor: '#FFF',
                      shadowColor: '#FF4D4D',
                      borderRadius: 10,
                      shadowBlur: 1,
                    }
                  },
                ],
                label: {
                  show: false,
                  position: 'center',
                  color: '#333333',
                },

                emphasis: {
                  label: {
                    show: true,
                    fontSize: 16,
                    fontWeight: 'bold'
                  }
                },
              },
            ],
          } : {
            legend: {
              right: 0,
              bottom: 0,
              orient: 'vertical',
              itemWidth: 20,
              itemHeight: 20,
              data: ['得分≤7', '得分>7'],
              formatter: function (name) {
                return name;
              }
            },
            tooltip: {
              trigger: 'item',
              formatter: (params) => {
                return `<div>
                    <div style="display: flex;align-items: center;height: 24rem">
                      <div style="width: 6rem;height: 6rem;background: ${params.color.colorStops[0].color};border-radius: 50%;margin-right: 8rem"></div>
                      ${params.name}
                    </div>
                    <div style="font-size: 18rem;font-weight: 500;color: #3D3D3D;margin-left: 12rem">${params.percent}%</div>
                  </div>`
              },
            },
            series: [
              {
                type: 'pie',
                radius: ['25%', '75%'], avoidLabelOverlap: false,
                data: [
                  {
                    name: '得分≤7', value: this.pie.gray, itemStyle: {
                      color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        y2: 1,
                        x2: 1,
                        colorStops: [
                          {offset: 0, color: '#B1C3FF'},
                          {offset: 1, color: '#C8D5FF'}
                        ], borderWidth: 4, borderColor: 'red'
                      },
                      borderWidth: this.pie.gray != 0 ? 4 : 0,
                      borderColor: '#FFF',
                      shadowColor: '#B1C3FF',
                      borderRadius: 10,
                      shadowBlur: 1,
                    }
                  },
                  {
                    name: '得分>7', value: this.pie.red, itemStyle: {
                      color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        y2: 1,
                        x2: 1,
                        colorStops: [
                          {offset: 0, color: '#FF4D4D'},
                          {offset: 1, color: '#FF7D7D'}
                        ],
                      },
                      borderWidth: this.pie.red != 0 ? 4 : 0,
                      borderColor: '#FFF',
                      shadowColor: '#FF4D4D',
                      borderRadius: 10,
                      shadowBlur: 1,
                    }
                  },
                ],
                label: {
                  show: false,
                  position: 'center',
                  color: '#333333',
                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: 16,
                    fontWeight: 'bold'
                  }
                },
              },
            ],
          };
          option && myChart.setOption(option);
          window.addEventListener('resize', function () {
            myChart.resize();
          });
        })
      }
    },
  }
}

</script>

<template>
  <div class="echarts-list-main">
    <div class="echarts-list-main-title">
      <div style="width: 6rem;height: 42rem;background: rgba(90,100,184,0.5);border-radius: 6rem 0 6rem 0;"></div>
      <span>{{ title }}</span>
    </div>
    <div class="echarts-list-main-content">
      <div v-if="tableData.length" class="echarts-list-main-content-table">
        <el-table :data="tableData" stripe style="width: 100%;margin-top: 18rem">
          <el-table-column label="姓名" prop="student"></el-table-column>
          <el-table-column :prop="index === '0'?'positive':'score'" label="得分"></el-table-column>
          <el-table-column label="行政班" prop="class"></el-table-column>
          <el-table-column label="年级" prop="grade"></el-table-column>
          <el-table-column label="校区" prop="school"></el-table-column>
          <el-table-column label="操作" width="60">
            <template slot-scope="scope">
              <el-button size="small" type="text" @click="onExamine(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div v-if="!Array.isArray(pie)" class="echarts-list-main-content-echarts">
        <div :id="'main-echarts'+index" style="width: 100%;height: 100%;"></div>
        <div v-if="!tableData.length">得分处于正常范围</div>
      </div>

      <div v-if="Array.isArray(pie)&&tableData.length===0">
        <t-result text="目前暂时还没有数据哦" type="empty"></t-result>
      </div>
    </div>
  </div>

</template>

<style lang="scss" scoped>
.echarts-list-main {
  width: 100%;
  min-height: 394rem;
  margin-top: 25rem;
  border-radius: 8rem 8rem 8rem 8rem;
  background: #FFFFFF !important;

  &-title {
    width: 100%;
    height: 42rem;
    display: flex;
    align-items: center;

    span {
      font-size: 20rem;
      font-weight: 500;
      color: #0B055B;
      margin-left: 22rem;
    }
  }

  &-content {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    min-height: calc(394rem - 42rem);
    margin-top: 22rem;
    padding: 0 1.61% 30rem;

    &-table {
      width: 65%;
    }

    &-echarts {
      font-size: 18rem;
      font-weight: 400;
      width: 35%;
      margin-bottom: 24rem;
      text-align: center;
      color: #666666;
    }
  }
}
</style>
